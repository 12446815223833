import React, { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useFadeAnimation } from "../../hooks/animation/use-fade-animation";
import { ifClassExists } from "../../utils/if-class-exists";
import { errorMessage } from "../form-commons";
import { CustomButton } from "../../components/button";
import useAxios from "axios-hooks";
import { REQUESTS_URL } from "../../api/url";
import { RequestDto } from "../../api/interfaces/request/request-dto";
import { RequestType } from "../../api/interfaces/request/request-type";

type SignupFormInputs = {
  name: string;
  mail: string;
  phone: string;
  organization: string;
  participants: string;

  communicationMethod: string;

  videoUrl: string;
  path: string;
  backgroundId: string;
}

export const useSignupForm = (
  courseId: string | undefined,
  selectedTypeUserTabIndex: number
) => {
  const [{
    data: savedRequestDto,
    response,
    loading
  }, postRequest] = useAxios<RequestDto>({
    url: REQUESTS_URL,
    method: "POST"
  }, {
    manual: true
  });

  const trigger = useFadeAnimation(".error-text");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignupFormInputs>();

  useEffect(() => trigger(), [
    trigger,
    errors
  ]);

  // const [{
  //   data: postCategoryDirectionResponseData,
  //   response: postCategoryDirectionResponse,
  //   loading: postCategoryDirectionResponseLoading,
  //   error: postCategoryDirectionResponseError
  // }, fetchPostCategoryDirection] = useAxios<void, PostCategoryDirectionDto>(
  //   {
  //     url: POST_CATEGORY_URL,
  //     method: 'POST',
  //   },
  //   {
  //     manual: true
  //   });


  const sendSign = function () {
    console.log('sendSign');
  }
  const onSubmit: SubmitHandler<SignupFormInputs> = useCallback(() => {
    if (!loading) {
      sendSign()
      postRequest({
        data: {
          name: watch("name"),
          course: {
            id: courseId
          },
          email: watch("mail"),
          phone: watch("phone"),
          organization: watch("organization"),
          participants: watch("participants"),
          communicationMethod: watch("communicationMethod"),
          type: selectedTypeUserTabIndex === 1 ? RequestType.legal_entity : RequestType.physical_person
        } as RequestDto
      });
    }
  }, [
    loading,
    postRequest,
    courseId,
    watch,
    selectedTypeUserTabIndex
  ]);

  return {
    SignupForm: (
      <form
        id="signup-form"
        onSubmit={handleSubmit(onSubmit)}>
        {selectedTypeUserTabIndex === 1 && (
          <>
            <div className="title">Информация об организации</div>

            <input
              className={`${ifClassExists('error', !!errors.organization)}`}
              placeholder="Название организации *"
              {...register("organization", { required: true, maxLength: 255 })}
            />

            {errorMessage(errors.organization)}

            <input
              className={`${ifClassExists('error', !!errors.participants)}`}
              placeholder="Число участников *"
              {...register("participants", { required: true, maxLength: 255 })}
            />

            {errorMessage(errors.participants)}
          </>
        )}

        <div className="title">Контактные данные</div>

        <input
          className={`${ifClassExists('error', !!errors.name)}`}
          placeholder="Фамилия Имя Отчество *"
          {...register("name", { required: true, maxLength: 255, pattern: /[^0-9]+/ })}
        />

        {errorMessage(errors.name)}

        <input
          className={`${ifClassExists('error', !!errors.mail)}`}
          placeholder="Электронная почта *"
          {...register("mail", {
            required: true,
            pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
          })}
        />

        {errorMessage(errors.mail)}

        <input
          className={`${ifClassExists('error', !!errors.phone)}`}
          placeholder="Телефон *"
          {...register("phone", { required: true, pattern: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/ })}
        />

        {errorMessage(errors.phone)}

        <div className="title">Способ связи</div>

        <select
          className={`${ifClassExists('error', !!errors.communicationMethod)} ${ifClassExists('default', !watch("communicationMethod"))}`}
          {...register("communicationMethod", { required: true })}
        >
          {/* <option disabled value="" selected>Способ связи *</option> */}
          <option value={"По почте"} selected>По почте</option>
          <option value={"По телефону"}>По телефону</option>
          <option value={"Любой"}>Любой</option>
        </select>

        {errorMessage(errors.communicationMethod)}
      </form>
    ),
    Buttons: (
      <div className='request-form-buttons'>
        <CustomButton
          form="signup-form"
          loading={loading}
          buttonType={"submit"}
          type={'button'}
          title={'Отправить'}
          classList={[
            'button--blue',
            'fsz16-lh20',
            'pd-bl-but2',
            'border--offline-color'
          ]}
        />

        <p className='request-form__text'>Нажимая "Отправить", вы принимаете <a
          href="https://itmo.ru/file/pages/79/personal_data_policy.pdf" target="_blank"
          rel="noreferrer">условия обработки персональных данных</a></p>
      </div>
    ),
    responseStatus: response?.status
  }
}
